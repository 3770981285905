/* our custom styles */

.wrapper {
  position: relative;
  display: block;
}

.principal {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    background: #232323;
}

.cookie-monster {
    background-color: @cookie-color;
    margin-bottom: 0px;
    padding: 20px 35px;
    color: @cookie-text-color;
    h5 {
        color: #fff;
        text-transform: none;
    }
    button {
        background: transparent;
        border: none;
        font-weight: bold;
        color: @cookie-link-color;
        cursor: pointer;
        outline: none;
        &:hover {
            color: @primary-color;
        }
        &:focus {
            outline: none;
        }
    }
    a {
        color: @cookie-link-color;
        text-decoration: none !important;
        &:hover {
            color: #fff;
        }
    }
}

.hide-cookie-monster {
    display: none;
}

body {
	color: #444;
	font-family: "Open Sans", Arial, sans-serif;
	font-size: 14px;
  line-height: 22px;
  @media (max-width: 575px) {
    font-size: 13px;
  }
  a {
    outline: none !important;
    text-decoration: none;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      line-height: 26px;
    }
  }
}

section {
  .container {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

#header-social {
  .nav {
    float: right;
    height: 30px;
    padding: 10px 0;
  }
  .social-icons {
    li {
      display: inline-block;
      margin: 0 3px 0 0;
      padding: 0;
      border-radius: 100%;
      overflow: visible;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
      a {
        background: #FFF;
        border-radius: 100%;
        display: block;
        height: 30px;
        line-height: 30px;
        width: 30px;
        text-align: center;
        align-items: center;
        color: #333 !important;
        cursor: pointer;
        padding: 2px 0px;
      }
    }
    li.social-icons-facebook:hover a {
      background: #33508f;
    }
    li.social-icons-twitter:hover a {
      background: #189fdd;
    }
    li.social-icons-linkedin:hover a {
      background: #0068a9;
    }
    li:hover i {
      color: #ffffff;
    }
  }
}

/* Header */
#header {
  position: relative;
  border-top: 3px solid #ededed;
  border-bottom: 10px solid transparent;
  .container {
    position: relative;
    padding-top: 14px;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  .navbar {
    margin-bottom: 0;
  }
  #header-top {
    padding: 20px 0 10px;
  }
  #header-social {
    margin-top: 10px;
  }
  #header-social li a {
    line-height: 10px;
  }
  #header-nav-top,
  #header-social .nav {
    float: right;
  }
  #header-nav-top > li > a,
  #header-nav-top > li > span,
  #header-nav-top > li > ul > li > a,
  #header-nav-top > li > ul > li > span {
    border-radius: 6px;
    color: #999;
    font-size: 12px;
    padding: 6px 10px;
    display: inline-block;
  }
  #header-nav-top li a:hover,
  #header-nav-top li a:focus {
    background: #EEE;
    text-decoration: none;
  }
  #header-nav-top li ul {
    border-left: 1px dotted silver;
  }
  #header-nav-top li li {
    margin-left: 2px;
  }
  .social-icons {
    li {
      display: inline-block;
      margin: 0 3px 0 0;
      padding: 0;
      border-radius: 100%;
      overflow: visible;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
      a {
        background: #FFF;
        border-radius: 100%;
        display: block;
        height: 30px;
        line-height: 30px;
        width: 30px;
        text-align: center;
        align-items: center;
        color: #333 !important;
        cursor: pointer;
        padding: 9px 10px;
      }
    }
    li.social-icons-facebook:hover a {
      background: #33508f;
    }
    li.social-icons-twitter:hover a {
      background: #189fdd;
    }
    li.social-icons-linkedin:hover a {
      background: #0068a9;
    }
    li:hover i {
      color: #ffffff;
    }
  }
  .header-btn-collapse-nav {
    background: #0088cc;
    i {
      color: #fff;
    }
  }
  /* Header Nav Main Desktop */
  @media (min-width: 992px) {
    .header-nav-wrapper {
      flex-direction: row;
      justify-content: space-between;
      padding-left: 0;
      padding-right: 0;
      .header-nav-main {
        order: -1;
        display: flex;
      }
    }
    .header-btn-collapse-nav {
      display: none !important;
    }

    .header-nav-main > ul > li {
      margin: 0 3px 0 0;
    }
    .header-nav-main > ul > li > a {
      border-radius: 4px;
      color: #0088cc;
      font-size: 13px;
      font-weight: 700;
      padding: 12px 12px;
      text-transform: uppercase;
      &:focus,
      &:hover {
        text-decoration: none;
        color: #FFFFFF;
        background-color: #0088cc;
      }
    }
    .header-nav-main > ul > li > a.active {
      color: #FFFFFF;
      background: #0088cc;
    }
  }
  /* Header Nav Main Mobile */
  @media (max-width: 991px) {
    .header-nav-wrapper {
      flex-direction: column;
    }
    .header-btn-collapse-nav {
      display: flex;
    }

    .header-nav-main {
      max-height: 50vh;
      overflow: hidden;
      overflow-y: auto;
    }
    .header-nav-main > ul {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      padding: 10px 0;
    }
    .header-nav-main > ul li {
      border-bottom: 1px solid #e8e8e8;
      clear: both;
      display: block;
      float: none;
      margin: 0;
      padding: 0;
      position: relative;
    }
    .header-nav-main > ul li:last-child {
      border-bottom: 0;
    }
    .header-nav-main > ul > li > a {
      display: block;
      text-transform: uppercase;
      font-weight: 700;
      margin-top: 1px;
      margin-bottom: 1px;
      color: #0088cc;
      &.active {
        background: #0088cc;
        color: #fff;
        border-bottom: 0;
      }
      &:hover {
        text-decoration: none;
      }
    }
    .header-nav-main > ul li a {
      font-size: 13px;
      font-style: normal;
      line-height: 20px;
      padding: 7px 8px;
      margin: 1px 0;
      border-radius: 4px;
      text-align: left;
    }

  }
}

#slider {
  background: #232323;
  .container {
    padding-top: 0;
    padding-bottom: 0;
  }
}

#skills {
  @media (max-width: 991px) {
    .container {
      flex-direction: column;
    }
  }
  .skill {
    padding: 0 10px;
  }
  .text {
    padding-left: 50px;
    color: @primary-text;
  }
  .title {
    font-size: 20px;
    color: @text-title-color;
    i {
      font-size: 26px;
      width: 50px;
    }
  }
}

.paralax-gray {
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  background: #fcfcfc;
  box-shadow: inset 0px 2px 0px 0px #f7f7f7, inset 0px -2px 0px 0px #f7f7f7;
}

#portfolio, .portfolio {
  .item {
    background: #fff;
    margin: 14px 10px;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 0 1px rgba(0, 0, 0, 0.1);
    a {
      color: @text-color;
      text-decoration: none;
      img {
        width: 100%;
        height: auto;
      }
      .text-box {
        background: #fff;
        border-radius: 0 0 5px 5px;
        padding: 20px;
        border-top: 1px solid #e5e5e5;
        text-align: center;
        .title {
          font-size: 20px;
          font-weight: 600;
          padding-bottom: 10px;
          height: 60px;
          line-height: 25px;
          overflow: hidden;
          color: @menu-hover-color;
        }
      }
    }
    h2 {
      font-size: 22px;
      font-weight: 600;
      padding-bottom: 10px;
      color: @menu-hover-color;
      a:hover {
        border-bottom: none;
      }
    }
    .nopadding {
      padding-left: 0;
    }
  }
}

#contact {
  .container {

  }
}

footer {
  color: #aaa;
  a {
    color: #eee;
  }
  &#portal-footer-wrapper {
    padding: 0;
    background: @darker-primary-color;
  }
  #portal-footer-top,
  #footer-top {
    padding-top: 40px;
    padding-bottom: 20px;
    background: @dark-primary-color;
    h4 {
      padding: 0;
      margin: 0 0 20px 0;
      color: #fff;
      font-weight: 400 !important;
    }
    .logo {
      margin-bottom: 20px;
    }
  }
  #portal-footer,
  #footer-bottom {
    padding-top: 20px;
    padding-bottom: 20px;
    background: @darker-primary-color;
    .container {
      .copyright, .links {
        padding: 14px 0;
      }
      .copyright {
        display: inline-block;
      }
      .links {
        li {
          display: inline-block;
          padding: 0 10px;
          line-height: 18px;
          a {
            color: @link-color;
            text-decoration: none;
            &:hover {
              color: @primary-color;
            }
          }
        }
      }
    }
    @media (max-width: 991px) {
      .container {
        flex-direction: column;
        .links {
          li {
            display: inline-block;
            padding: 0 14px 0 0;
            line-height: 18px;
            a {
              padding: 10px 14px 10px 0;
              color: @link-color;
              text-decoration: none;
              &:hover {
                color: @primary-color;
              }
            }
          }
        }
      }
    }
  }
  .footer-links {
    list-style: none;
    li {
      margin-bottom: 20px;
    }
    i {
      margin-right: 14px;
    }
  }
  .footer-contact-details {
    list-style: none;
    i {
      color: @primary-color;
      margin-right: 14px;
    }
    span {
      color: @primary-color;
      padding-right: 10px;
    }
    a {
      color: @link-color;
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: 768px) {
  footer .footer-bottom {
    .copyright {
      float: left;
    }
    .links {
      margin-top: 10px;
      float: left;
    }
  }
}

.flickr-images {
  display: grid;
  grid-template-areas: "a a a";
  grid-gap: 10px;
  .flickr-photo {
      img {
          border: 10px solid @darker-primary-color;
          &:hover {
              border-color: @accent-color;
          }
      }
  }
}

@media only screen and (max-width: 992px) {
  .flickr-images {
      display: grid;
      grid-template-areas: "a a";
  }
}

@media only screen and (max-width: 768px) {
  .flickr-images {
      display: grid;
      grid-template-areas: "a a a a";
  }
}

@media only screen and (max-width: 480px) {
  .flickr-images {
      display: grid;
      grid-template-areas: "a a a";
  }
}

.child-col-margin div {
  margin-bottom: 15px;
}

/* TinyMCE templates */

// image-grid-2x2:
.image-grid-2x2 {
    padding: 0;
    margin: 0 0 @plone-grid-gutter-width 0;
    list-style: none;
    .make-row();

    > li {
        .make-xs-column(12);
        .make-sm-column(6);
        margin-top: @plone-grid-gutter-width;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

#portal-globalnav-wrapper .container {
  padding: 0;
}

#mainnavigation {
  width: 100%;
  .plone-navbar {
    margin-bottom: 0px;
    z-index: 1;
  }
  .plone-navbar-nav {
    margin: 0;
  }
}

.plone-breadcrumb {
  margin-top: 0;
  margin-bottom: 0;
}

#portal-searchbox {
  @media screen and (min-width: 768px) {
    max-width: 100%;
  }
}

.carousel-inner img {
  width: 100%;
}

.outer-wrapper {
  padding-top: 40px;
}

#team {
  .member {
    border: 1px solid silver;
    background: #ffffff;
    margin-bottom: 20px;
    p.name {
      text-align: center;
      font-size: 15px;
      font-weight: 600;
      padding: 15px 0 5px;
      margin: 0;
      color: @menu-hover-color;
    }
    p.role {
      text-align: center;
      margin: 0;
      padding: 0 0 10px;
    }
  }
}
